
.footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #1F2022;
    padding: 20px;
}

.brand {
    color: white;
    font-family: 'Cinzel', serif;
    font-weight: 500;
    font-size: 25px;
}
