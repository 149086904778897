
.testimonials {
    width: 100%;
    height: auto;
    padding: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    background: url('../res/bg-flower-2.png');
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
}

.customer_review {
    width: 100%;
    display: flex;
    justify-content: center;
}

.heading {
    font-family: 'Gwendolyn', sans-serif;
    color: #7851a9;
    font-size: 60px;
}

.underline {
    width: 100px;
    height: 2px;
    background-color: #7851a9;
    margin: 10px auto;
}

.sub_heading {
    font-family: 'Cormorant Garamond', sans-serif;
    font-size: 35px;
}

.reviews_outer_div {
    display: flex;
    width: 100%;
}

.review_div {
    width: 50%;
}

.review_div h2 {
    font-family: 'Work sans', sans-serif;
    color: #7851a9;
    font-size: 40px;
    font-weight: 300;
}

.review_div img {
    width: 30px;
    margin: 20px 10px;
}

.review_div p {
    font-family: 'Cormorant Garamond', sans-serif;
    font-size: 30px;
}

.arrow_button_div {
    display: flex;
    width: 25%;
}

.arrow_button {
    height: fit-content;
    padding: 10px;
    background-color: rgb(120,81,169,0.8);
    border: 0;
    border-radius: 5px;
}


@media (max-width: 1050px) {

    .heading {
        font-size: 50px;
    }

    .sub_heading {
        font-size: 30px;
    }

    .review_div {
        width: 60%;
    }

    .review_div h2 {
        font-size: 35px;
    }

    .review_div p {
        font-size: 25px;
    }

    .arrow_button_div {
        width: 20%;
    }

    .arrow_button img {
        width: 30px;
    }
}

@media (max-width: 820px) {

    .testimonials {
        padding: 20px;
    }

    .sub_heading {
        margin: 20px auto;
    }
}

@media (max-width: 680px) {
    
    .buttons_div {
        margin: 20px auto;
        width: 100%;
    }
    
    .buttons_div button {
        width: 100%;
    }
}

@media (max-width: 600px) {

    .review_div h2 {
        font-size: 30px;
    }

    .review_div img {
        width: 25px;
        margin: 20px 5px;
    }
}

@media (max-width: 400px) {

    .sub_heading {
        font-size: 25px;
    }

    .review_div h2 {
        font-size: 25px;
    }

    .review_div p {
        font-size: 20px;
        margin: 0 10px;
    }

    .review_div img {
        width: 18px;
        margin: 15px 5px;
    }

    .buttons_div {
        padding: 0 20px;
    }
}