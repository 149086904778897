
.home{
    width: 100%;
    height: 100%;
}

.plx1 {
    background-image: url("../res/parallax-1.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    height: 50vh;
}

.plx2 {
    background-image: url("../res/parallax-2.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    height: 50vh;
}