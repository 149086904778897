
.header {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
}

.toggle {
    border: 0;
    color: white;
    background: none;
    display: none;
}

.brand_div{
    margin-left: 20px;
    width: 250px;
}

.brand {
    color: white;
    font-family: 'Cinzel', serif;
    font-weight: 500;
    font-size: 25px;
    cursor: pointer;
}


@media (max-width: 820px) {

    .toggle {
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 2;
    }
}

@media (max-width: 380px) {

    .brand_div{
        margin: 0px;
    }
    
    .brand {
        font-size: 22px;
    }
}