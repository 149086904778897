
.contact_display {
  background-image: url("../res/bg-flower-3.png");
  background-position: center;
}

.contact_display a {
  text-decoration: none;
}

.outer_div {
  display: flex;
}


@media (max-width: 1400px) {
  
  .outer_div {
    flex-direction: column;
  }
}


/* === INFO  ========================= */
.contact_info {
  width: 50%;
  padding: 150px;
}

.heading {
  font-family: 'Gwendolyn', sans-serif;
  color: #7851a9;
  font-size: 60px;
}

.underline {
  width: 100px;
  height: 2px;
  background-color: #7851a9;
  margin: 10px;
}

.sub_heading {
  font-family: 'Cormorant Garamond', sans-serif;
  font-size: 35px;
}

.phone_no_div {
  padding: 25px 0;
}

.phone_no {
  font-family: 'Work Sans', sans-serif;
  color: #7851a9;
  padding: 0%;
  margin: 0%;
  font-size: 50px;
}

.address {
  font-family: 'Work Sans', sans-serif;
  font-size: 25px;
}

.email {
  color: #7851a9;
  font-family: 'Work Sans', sans-serif;
  font-size: 25px;
  font-weight: 600;
}

.buttons_div {
  padding: 10px 0;
}


@media (max-width: 1500px) {
  
  .contact_info {
    width: 40%;
    padding: 100px;
  }
}

@media (max-width: 1400px) {
  
  .contact_info {
    text-align: center;
    width: 100%;
    padding-bottom: 0px;
  }

  .underline {
    margin: 10px auto;
  }
}

@media (max-width: 820px) {
  
  .contact_info {
    padding: 50px;
  }

  .heading {
    font-size: 50px;
  }
  
  .sub_heading {
    font-size: 30px;
  }

  .phone_no {
    font-size: 40px;
  }
  
  .address {
    font-size: 20px;
  }
  
  .email {
    font-size: 20px;
  }
}

@media (max-width: 680px) {

  .buttons_div {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 450px) {
  
  .contact_info {
    padding: 20px;
  }

  .sub_heading {
    font-size: 25px;
  }

  .phone_no {
    font-size: 30px;
  }
}


/* === FORM MAP ========================= */
.map_div {
  width: 100%;
  height: 50vh;
  min-height: 500px;
}

.map_div iframe {
  width: 100%;
  height: 100%;
}