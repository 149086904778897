
.btn {
    background: rgb(255, 255, 255, 0.8);
    border: 0;
    border-radius: 5px;
    padding: 10px 10px;
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
    transition: 0.3s ease;
    margin: 5px;
    min-width: 200px;
}

.btn img {
    width: 35px;
    margin-right: 5px;
}