
.display_part {
    width: 100%;
    height: 100vh;
    background-color: #1F2022;
    background-image: url("../res/main-display-bg.png");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}


/*@media (max-width: ____) {

}*/


.display_container {
    width: 100%;
    height: 85vh;
}

.main_heading {
    font-size: 120px;
    font-family: 'Cinzel', serif;
    font-weight: 550;
    color: white;    
    padding: 0;
    margin: 0;
    text-shadow: 4px 4px 10px #1F2022;
}

.sub_heading {
    font-size: 50px;
    font-family: 'Cinzel', serif;
    font-weight: 300;
    color: white;    
    padding: 0; 
    text-shadow: 2px 2px 5px #1F2022;
}

.button_container {
    width: 50%;
    display: flex;
    justify-content: space-between;
    position: relative;
    left: 50%;
}

.arrow_down {
    width: 50px;
    margin: 0;
    transition: 0.3s ease;
    cursor: pointer;
}

.arrow_down:hover {
    transform: scale(1.2);
}

.contact_container {
    display: flex;
    padding: 5px;
}


@media (max-height: 900px) {

    .display_container {
        height: 80vh;
    }
} 

@media (max-height: 700px) {

    .display_container {
        height: 75vh;
    }
}

@media (max-height: 550px) {

    .display_container {
        height: 70vh;
    }
}

@media (max-height: 450px) {

    .display_part, .display_container {
        height: auto;
    }
}

@media (max-width: 1250px) {

    .main_heading {
        font-size: 80px;
    }

    .sub_heading {
        font-size: 40px;
    }

    .design {
        width: 250px;
    }
}

@media (max-width: 1050px) {

    .display_container {
        height: 75vh;
    }

    .contact_container {
        flex-direction: column;
    }

    @media (max-height: 900px) {

        .display_container {
            height: 75vh;
        }
    } 
    
    @media (max-height: 800px) {
    
        .display_container {
            height: 70vh;
        }
    }
    
    @media (max-height: 700px) {
    
        .display_container {
            height: 65vh;
        }
    }

    @media (max-height: 600px) {
    
        .display_container {
            height: 60vh;
        }
    }

    @media (max-height: 500px) {
    
        .display_part, .display_container {
            height: auto;
        }
    }
}

@media (max-width: 880px) {

    .main_heading {
        font-size: 60px;
    }

    .sub_heading {
        font-size: 30px;
    }

    .design {
        max-width: 200px;
    }
}

@media (max-width: 680px) {

    .display_container {
        height: 70vh;
    }

    .main_heading {
        font-size: 40px;
    }

    .sub_heading {
        font-size: 20px;
    }

    .design {
        max-width: 150px;
    }

    .button_container {
        width: 100%;
        left: 0;
        flex-direction: column-reverse;
    }

    .arrow_down {
        margin: auto;
    }

    @media (max-height: 900px) {

        .display_container {
            height: 65vh;
        }
    } 
    
    @media (max-height: 750px) {
    
        .display_container {
            height: 60vh;
        }
    }
    
    @media (max-height: 650px) {
    
        .display_container {
            height: 55vh;
        }
    }

    @media (max-height: 600px) {
    
        .display_container {
            height: 50vh;
        }
    }

    @media (max-height: 400px) {
    
        .display_part, .display_container {
            height: auto;
        }
    }
}

@media (max-width: 400px) {

    .main_heading {
        font-size: 30px;
    }

    .sub_heading {
        font-size: 15px;
    }
}