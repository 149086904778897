
.about{
    width: 100%;
    height: 100%;
}

.plx1 {
    background-image: url("../res/parallax-3.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    height: 50vh;
}

.plx2 {
    background-image: url("../res/parallax-4.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    height: 50vh;
}

.gallery_div {
    padding-top: 80px;
    padding-bottom: 80px;
}


@media (max-width: 820px) {
    
    .gallery_div {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}