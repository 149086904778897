
.outer_div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.inner_div {
    margin: auto;
    text-align: center;
}