
.contact_display_full {
    background-image: url("../res/bg-flower-3.png");
    background-position: center;
}

.contact_display_full a {
    text-decoration: none;
}

.top_div, .bottom_div {
    display: flex;
}


@media (max-width: 1400px) {
    
    .top_div, .bottom_div  {
    flex-direction: column;
    }
}


/* === TOP  ========================= */
.contact_info {
    width: 50%;
    padding: 150px;
}

.heading {
    font-family: 'Gwendolyn', sans-serif;
    color: #7851a9;
    font-size: 60px;
}

.underline {
    width: 100px;
    height: 2px;
    background-color: #7851a9;
    margin: 10px;
}

.sub_heading {
    font-family: 'Cormorant Garamond', sans-serif;
    font-size: 35px;
}

.buttons_div {
    padding: 10px 0;
}


@media (max-width: 1500px) {
    
    .contact_info {
    width: 40%;
    padding: 100px;
    }
}

@media (max-width: 1400px) {
    
    .contact_info {
    text-align: center;
    width: 100%;
    padding-bottom: 0px;
    }

    .underline {
    margin: 10px auto;
    }
}

@media (max-width: 820px) {
    
    .contact_info {
    padding: 50px;
    }

    .heading {
    font-size: 50px;
    }
    
    .sub_heading {
    font-size: 30px;
    }
}

@media (max-width: 680px) {

    .buttons_div {
    display: flex;
    flex-direction: column;
    }
}

@media (max-width: 450px) {
    
    .contact_info {
    padding: 20px;
    }

    .sub_heading {
    font-size: 25px;
    }
}


/* === BOTTOM  ========================= */
.address_div {
    width: 50%;
    padding: 150px;
}

.address {
    font-family: 'Work Sans', sans-serif;
    font-size: 25px;
    margin-bottom: 50px;
}

.email {
    color: #7851a9;
    font-family: 'Work Sans', sans-serif;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 50px;
}

.map_div {
    width: 100%;
    min-height: 500px;
    padding: 20px;
}

.map_div iframe {
    width: 100%;
    height: 100%;
}

@media (max-width: 1500px) {
    
    .address_div {
    width: 40%;
    padding: 100px;
    }
}

@media (max-width: 1400px) {
    
    .address_div {
    text-align: center;
    width: 100%;
    padding-bottom: 0px;
    }

    .map_div {
        padding: 0;
        height: 80vh;
    }
}

@media (max-width: 820px) {
    
    .address_div {
    padding: 50px;
    }
    
    .address {
    font-size: 20px;
    }
    
    .email {
    font-size: 20px;
    }

    .map_div {
        height: 50vh;
        padding: 0;
    }
}

@media (max-width: 450px) {
    
    .address_div {
    padding: 20px;
    }
}