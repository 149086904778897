@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Gwendolyn:wght@700&display=swap);


.Home_home__r4ELY{
    width: 100%;
    height: 100%;
}

.Home_plx1__FUSHh {
    background-image: url(/static/media/parallax-1.581a15f9.jpg);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    height: 50vh;
}

.Home_plx2__10gOl {
    background-image: url(/static/media/parallax-2.a17a6956.jpg);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    height: 50vh;
}

.BottomNav_footer__3-lWk {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #1F2022;
    padding: 20px;
}

.BottomNav_brand__MahGV {
    color: white;
    font-family: 'Cinzel', serif;
    font-weight: 500;
    font-size: 25px;
}


nav {
    float: right;
}

nav ul {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-right: 30px;
    list-style: none;
}

nav ul li {
    margin-left: 30px;
}

nav a {
    font-size: 25px;
    text-decoration: none;
    color: rgb(255,255,255,0.7);
    transition: 0.3s;
    font-family: 'Raleway', sans-serif;
    text-shadow: 2px 2px 5px #1F2022;
}

nav a.PageNav_active__26NWm,
nav a:hover {
    color: rgb(255,255,255,1);
}

nav span {
    margin-left: 30px;
    color: rgb(255,255,255,0.7);
}


@media (max-width: 900px) {
    
    nav a {
        font-size: 22px;
    }
}

@media (max-width: 820px){

    nav {
        flex-direction: column;
    }

    @media (max-height: 350px){

        nav {
            overflow: scroll;
        }
    }

    nav.PageNav_collapse__3yPFh {
        position: fixed;
        width: 100%;
        height: 100vh;
        background-color: #1F2022;
        right: -100%;
        z-index: 1;
        transition: all 0.5s;
    }

    nav ul {
        flex-direction: column;
    }

    nav ul li {
        margin: 15px;
    }

    nav span {
        display: none;
    }
}

.CentreDiv_outer_div__11Xm1 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.CentreDiv_inner_div__3hNUs {
    margin: auto;
    text-align: center;
}

.MainDisplay_display_part__Q1Ddh {
    width: 100%;
    height: 100vh;
    background-color: #1F2022;
    background-image: url(/static/media/main-display-bg.89c4d1cd.png);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}


/*@media (max-width: ____) {

}*/


.MainDisplay_display_container__1xLfA {
    width: 100%;
    height: 85vh;
}

.MainDisplay_main_heading__22WMZ {
    font-size: 120px;
    font-family: 'Cinzel', serif;
    font-weight: 550;
    color: white;    
    padding: 0;
    margin: 0;
    text-shadow: 4px 4px 10px #1F2022;
}

.MainDisplay_sub_heading__s2vpr {
    font-size: 50px;
    font-family: 'Cinzel', serif;
    font-weight: 300;
    color: white;    
    padding: 0; 
    text-shadow: 2px 2px 5px #1F2022;
}

.MainDisplay_button_container__3csBW {
    width: 50%;
    display: flex;
    justify-content: space-between;
    position: relative;
    left: 50%;
}

.MainDisplay_arrow_down__1toiZ {
    width: 50px;
    margin: 0;
    transition: 0.3s ease;
    cursor: pointer;
}

.MainDisplay_arrow_down__1toiZ:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.MainDisplay_contact_container__3rN3W {
    display: flex;
    padding: 5px;
}


@media (max-height: 900px) {

    .MainDisplay_display_container__1xLfA {
        height: 80vh;
    }
} 

@media (max-height: 700px) {

    .MainDisplay_display_container__1xLfA {
        height: 75vh;
    }
}

@media (max-height: 550px) {

    .MainDisplay_display_container__1xLfA {
        height: 70vh;
    }
}

@media (max-height: 450px) {

    .MainDisplay_display_part__Q1Ddh, .MainDisplay_display_container__1xLfA {
        height: auto;
    }
}

@media (max-width: 1250px) {

    .MainDisplay_main_heading__22WMZ {
        font-size: 80px;
    }

    .MainDisplay_sub_heading__s2vpr {
        font-size: 40px;
    }

    .MainDisplay_design__FCdpP {
        width: 250px;
    }
}

@media (max-width: 1050px) {

    .MainDisplay_display_container__1xLfA {
        height: 75vh;
    }

    .MainDisplay_contact_container__3rN3W {
        flex-direction: column;
    }

    @media (max-height: 900px) {

        .MainDisplay_display_container__1xLfA {
            height: 75vh;
        }
    } 
    
    @media (max-height: 800px) {
    
        .MainDisplay_display_container__1xLfA {
            height: 70vh;
        }
    }
    
    @media (max-height: 700px) {
    
        .MainDisplay_display_container__1xLfA {
            height: 65vh;
        }
    }

    @media (max-height: 600px) {
    
        .MainDisplay_display_container__1xLfA {
            height: 60vh;
        }
    }

    @media (max-height: 500px) {
    
        .MainDisplay_display_part__Q1Ddh, .MainDisplay_display_container__1xLfA {
            height: auto;
        }
    }
}

@media (max-width: 880px) {

    .MainDisplay_main_heading__22WMZ {
        font-size: 60px;
    }

    .MainDisplay_sub_heading__s2vpr {
        font-size: 30px;
    }

    .MainDisplay_design__FCdpP {
        max-width: 200px;
    }
}

@media (max-width: 680px) {

    .MainDisplay_display_container__1xLfA {
        height: 70vh;
    }

    .MainDisplay_main_heading__22WMZ {
        font-size: 40px;
    }

    .MainDisplay_sub_heading__s2vpr {
        font-size: 20px;
    }

    .MainDisplay_design__FCdpP {
        max-width: 150px;
    }

    .MainDisplay_button_container__3csBW {
        width: 100%;
        left: 0;
        flex-direction: column-reverse;
    }

    .MainDisplay_arrow_down__1toiZ {
        margin: auto;
    }

    @media (max-height: 900px) {

        .MainDisplay_display_container__1xLfA {
            height: 65vh;
        }
    } 
    
    @media (max-height: 750px) {
    
        .MainDisplay_display_container__1xLfA {
            height: 60vh;
        }
    }
    
    @media (max-height: 650px) {
    
        .MainDisplay_display_container__1xLfA {
            height: 55vh;
        }
    }

    @media (max-height: 600px) {
    
        .MainDisplay_display_container__1xLfA {
            height: 50vh;
        }
    }

    @media (max-height: 400px) {
    
        .MainDisplay_display_part__Q1Ddh, .MainDisplay_display_container__1xLfA {
            height: auto;
        }
    }
}

@media (max-width: 400px) {

    .MainDisplay_main_heading__22WMZ {
        font-size: 30px;
    }

    .MainDisplay_sub_heading__s2vpr {
        font-size: 15px;
    }
}

.MainNav_header__2251v {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
}

.MainNav_toggle__1Aczb {
    border: 0;
    color: white;
    background: none;
    display: none;
}

.MainNav_brand_div__1zUpp{
    margin-left: 20px;
    width: 250px;
}

.MainNav_brand__1DJ-Y {
    color: white;
    font-family: 'Cinzel', serif;
    font-weight: 500;
    font-size: 25px;
    cursor: pointer;
}


@media (max-width: 820px) {

    .MainNav_toggle__1Aczb {
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 2;
    }
}

@media (max-width: 380px) {

    .MainNav_brand_div__1zUpp{
        margin: 0px;
    }
    
    .MainNav_brand__1DJ-Y {
        font-size: 22px;
    }
}

.Button_btn__2b3Dm {
    background: rgb(255, 255, 255, 0.8);
    border: 0;
    border-radius: 5px;
    padding: 10px 10px;
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
    transition: 0.3s ease;
    margin: 5px;
    min-width: 200px;
}

.Button_btn__2b3Dm img {
    width: 35px;
    margin-right: 5px;
}

.AboutBrand_about_brand__33Vj3 {
    width: 100%;
    padding: 100px;
    background: url(/static/media/bg-flower-1.13683b5c.png);
    background-size: auto;
    background-position: top;
    background-repeat: no-repeat;
}

@media (max-width: 1230px) {

    .AboutBrand_about_brand__33Vj3 {
        padding: 80px;
    }
}

@media (max-width: 850px) {

    .AboutBrand_about_brand__33Vj3 {
        padding: 50px;
    }
}

@media (max-width: 680px) {

    .AboutBrand_about_brand__33Vj3 {
        padding: 20px;
    }
}


/* === Top Side  ========================= */
.AboutBrand_about_div_top__1BjwY {
    width: 100%;
}

.AboutBrand_about_div__OyxwR, .AboutBrand_features_div__knd_D, .AboutBrand_buttons_div__3DilM {
    width: 70%;
    margin: auto;
}

.AboutBrand_about_div__OyxwR {
    text-align: center;
}

.AboutBrand_about_div__OyxwR h2 {
    font-family: 'Gwendolyn', sans-serif;
    color: #7851a9;
    font-size: 60px;
}

.AboutBrand_underline__11own {
    width: 100px;
    height: 2px;
    background-color: #7851a9;
    margin: 10px auto;
}

.AboutBrand_about_div__OyxwR p {
    font-family: 'Cormorant Garamond', sans-serif;
    font-size: 30px;
}

.AboutBrand_features_div__knd_D, .AboutBrand_buttons_div__3DilM {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 80px;
}

.AboutBrand_features_div__knd_D figure {
    text-align: center;
    max-width: 200px;
    margin: 20px;
}

.AboutBrand_features_div__knd_D img{
    width: 100px;
}

.AboutBrand_features_div__knd_D figcaption {
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
    margin: 0 10px;
}


@media (max-width: 1640px) {

    .AboutBrand_about_div__OyxwR, .AboutBrand_features_div__knd_D, .AboutBrand_buttons_div__3DilM {
        width: 80%;
    }
}

@media (max-width: 1470px) {

    .AboutBrand_about_div__OyxwR, .AboutBrand_features_div__knd_D, .AboutBrand_buttons_div__3DilM {
        width: 90%;
    }
}

@media (max-width: 1340px) {

    .AboutBrand_about_div__OyxwR, .AboutBrand_features_div__knd_D, .AboutBrand_buttons_div__3DilM {
        width: 100%;
        margin-top: 60px;
    }
}

@media (max-width: 820px) {

    .AboutBrand_about_div__OyxwR h2 {
        font-size: 50px;
    }
    
    .AboutBrand_about_div__OyxwR p {
        font-size: 25px;
    }

    .AboutBrand_features_div__knd_D {
        margin-top: 0;
    }
}

@media (max-width: 680px) {
    
    .AboutBrand_buttons_div__3DilM {
        flex-direction: column;
    }
}


/* === Bottom Side  ========================= */
.AboutBrand_about_div_bottom__M8mKi {
    margin-top: 100px;
    width: 100%;
}


.PicsCollage_pics_div__Y3Kh4 {
    display: flex;
    justify-content: center;
    margin: auto;
    height: 100%;
}

.PicsCollage_inner_pics_div1__1JWTf, .PicsCollage_inner_pics_div2__uhGH_, .PicsCollage_inner_pics_div3__37wLr {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.PicsCollage_empty_div__1AsHg, .PicsCollage_gallery_pic__3hcEB, .PicsCollage_btn_div__3zy2B {
    width: 300px;
    margin: 10px;
}

.PicsCollage_empty_div__1AsHg {
    height: 90px;
}

.PicsCollage_btn_div__3zy2B {
    height: 200px;
}

.PicsCollage_btn_div__3zy2B h1{
    font-family: 'Gwendolyn', sans-serif;
    color: #7851a9;
    font-size: 60px;
}

.PicsCollage_underline__2MDYv {
    width: 100px;
    height: 2px;
    background-color: #7851a9;
    margin: 10px auto;
}


@media (max-width: 1050px) {

    .PicsCollage_pics_div__Y3Kh4 {
        margin-bottom: 20px;
    }
    
    .PicsCollage_inner_pics_div3__37wLr {
        display: none;
    }
}

@media (max-width: 750px) {

    .PicsCollage_pics_div__Y3Kh4 {
        margin-bottom: 40px;
    }
    
    .PicsCollage_inner_pics_div1__1JWTf {
        display: none;
    }
}

@media (max-width: 680px) {

    .PicsCollage_btn_div__3zy2B h1{
        font-size: 50px;
    }
}

@media (max-width: 370px) {

    .PicsCollage_gallery_pic__3hcEB, .PicsCollage_btn_div__3zy2B {
        width: 250px;
    }
}

.Testimonials_testimonials__30tm9 {
    width: 100%;
    height: auto;
    padding: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    background: url(/static/media/bg-flower-2.f93a5850.png);
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
}

.Testimonials_customer_review__1ryyw {
    width: 100%;
    display: flex;
    justify-content: center;
}

.Testimonials_heading__1EDBr {
    font-family: 'Gwendolyn', sans-serif;
    color: #7851a9;
    font-size: 60px;
}

.Testimonials_underline__Sea-X {
    width: 100px;
    height: 2px;
    background-color: #7851a9;
    margin: 10px auto;
}

.Testimonials_sub_heading__3UeXB {
    font-family: 'Cormorant Garamond', sans-serif;
    font-size: 35px;
}

.Testimonials_reviews_outer_div__gqgt4 {
    display: flex;
    width: 100%;
}

.Testimonials_review_div__2A1jx {
    width: 50%;
}

.Testimonials_review_div__2A1jx h2 {
    font-family: 'Work sans', sans-serif;
    color: #7851a9;
    font-size: 40px;
    font-weight: 300;
}

.Testimonials_review_div__2A1jx img {
    width: 30px;
    margin: 20px 10px;
}

.Testimonials_review_div__2A1jx p {
    font-family: 'Cormorant Garamond', sans-serif;
    font-size: 30px;
}

.Testimonials_arrow_button_div__3Ry3T {
    display: flex;
    width: 25%;
}

.Testimonials_arrow_button__OGkFR {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 10px;
    background-color: rgb(120,81,169,0.8);
    border: 0;
    border-radius: 5px;
}


@media (max-width: 1050px) {

    .Testimonials_heading__1EDBr {
        font-size: 50px;
    }

    .Testimonials_sub_heading__3UeXB {
        font-size: 30px;
    }

    .Testimonials_review_div__2A1jx {
        width: 60%;
    }

    .Testimonials_review_div__2A1jx h2 {
        font-size: 35px;
    }

    .Testimonials_review_div__2A1jx p {
        font-size: 25px;
    }

    .Testimonials_arrow_button_div__3Ry3T {
        width: 20%;
    }

    .Testimonials_arrow_button__OGkFR img {
        width: 30px;
    }
}

@media (max-width: 820px) {

    .Testimonials_testimonials__30tm9 {
        padding: 20px;
    }

    .Testimonials_sub_heading__3UeXB {
        margin: 20px auto;
    }
}

@media (max-width: 680px) {
    
    .Testimonials_buttons_div__1UeKm {
        margin: 20px auto;
        width: 100%;
    }
    
    .Testimonials_buttons_div__1UeKm button {
        width: 100%;
    }
}

@media (max-width: 600px) {

    .Testimonials_review_div__2A1jx h2 {
        font-size: 30px;
    }

    .Testimonials_review_div__2A1jx img {
        width: 25px;
        margin: 20px 5px;
    }
}

@media (max-width: 400px) {

    .Testimonials_sub_heading__3UeXB {
        font-size: 25px;
    }

    .Testimonials_review_div__2A1jx h2 {
        font-size: 25px;
    }

    .Testimonials_review_div__2A1jx p {
        font-size: 20px;
        margin: 0 10px;
    }

    .Testimonials_review_div__2A1jx img {
        width: 18px;
        margin: 15px 5px;
    }

    .Testimonials_buttons_div__1UeKm {
        padding: 0 20px;
    }
}

.ContactDisplay_contact_display__Ng9Rp {
  background-image: url(/static/media/bg-flower-3.d89f1e2c.png);
  background-position: center;
}

.ContactDisplay_contact_display__Ng9Rp a {
  text-decoration: none;
}

.ContactDisplay_outer_div__UIEFI {
  display: flex;
}


@media (max-width: 1400px) {
  
  .ContactDisplay_outer_div__UIEFI {
    flex-direction: column;
  }
}


/* === INFO  ========================= */
.ContactDisplay_contact_info__2C-tA {
  width: 50%;
  padding: 150px;
}

.ContactDisplay_heading__36K1s {
  font-family: 'Gwendolyn', sans-serif;
  color: #7851a9;
  font-size: 60px;
}

.ContactDisplay_underline__VycNU {
  width: 100px;
  height: 2px;
  background-color: #7851a9;
  margin: 10px;
}

.ContactDisplay_sub_heading__1PAvX {
  font-family: 'Cormorant Garamond', sans-serif;
  font-size: 35px;
}

.ContactDisplay_phone_no_div__1xkpQ {
  padding: 25px 0;
}

.ContactDisplay_phone_no__3_uFs {
  font-family: 'Work Sans', sans-serif;
  color: #7851a9;
  padding: 0%;
  margin: 0%;
  font-size: 50px;
}

.ContactDisplay_address__T6w1V {
  font-family: 'Work Sans', sans-serif;
  font-size: 25px;
}

.ContactDisplay_email__h7tAm {
  color: #7851a9;
  font-family: 'Work Sans', sans-serif;
  font-size: 25px;
  font-weight: 600;
}

.ContactDisplay_buttons_div__3dP9K {
  padding: 10px 0;
}


@media (max-width: 1500px) {
  
  .ContactDisplay_contact_info__2C-tA {
    width: 40%;
    padding: 100px;
  }
}

@media (max-width: 1400px) {
  
  .ContactDisplay_contact_info__2C-tA {
    text-align: center;
    width: 100%;
    padding-bottom: 0px;
  }

  .ContactDisplay_underline__VycNU {
    margin: 10px auto;
  }
}

@media (max-width: 820px) {
  
  .ContactDisplay_contact_info__2C-tA {
    padding: 50px;
  }

  .ContactDisplay_heading__36K1s {
    font-size: 50px;
  }
  
  .ContactDisplay_sub_heading__1PAvX {
    font-size: 30px;
  }

  .ContactDisplay_phone_no__3_uFs {
    font-size: 40px;
  }
  
  .ContactDisplay_address__T6w1V {
    font-size: 20px;
  }
  
  .ContactDisplay_email__h7tAm {
    font-size: 20px;
  }
}

@media (max-width: 680px) {

  .ContactDisplay_buttons_div__3dP9K {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 450px) {
  
  .ContactDisplay_contact_info__2C-tA {
    padding: 20px;
  }

  .ContactDisplay_sub_heading__1PAvX {
    font-size: 25px;
  }

  .ContactDisplay_phone_no__3_uFs {
    font-size: 30px;
  }
}


/* === FORM MAP ========================= */
.ContactDisplay_map_div__3Xzh3 {
  width: 100%;
  height: 50vh;
  min-height: 500px;
}

.ContactDisplay_map_div__3Xzh3 iframe {
  width: 100%;
  height: 100%;
}

.Form_form_div__1C5Fd {
    width: 50%;
    padding: 50px;  
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    margin: 100px;
  }
  
  .Form_form__2hemT {
    padding: 1rem;
  }
  
  .Form_input_div__1EDso {
    margin-bottom: 0.5rem;
  }
  
  .Form_input_div__1EDso label {
    display: block;
    font-weight: bold;
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
    margin-bottom: 0.5rem;
  }
    
  .Form_input_div__1EDso input,
  .Form_input_div__1EDso textarea {
    display: block;
    font: inherit;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 0.25rem;
    width: 100%;
  }
    
  .Form_action__1KdBi {
    margin-top: 1rem;
    text-align: center;
  }
    
  .Form_action__1KdBi button {
    cursor: pointer;
  }

  .Form_output_div__1g1di {
    width: 100%;
    text-align: center;
  }
  
  
  @media (max-width: 1500px) {
    
    .Form_form_div__1C5Fd {
      width: 60%;
    }
  }
  
  @media (max-width: 1400px) {
    
    .Form_form_div__1C5Fd {
      width: 80%;
      margin: 80px auto;
    }
  }
  
  @media (max-width: 820px) {
    
    .Form_form_div__1C5Fd {
      width: 100%;
      margin: 0;
      margin-top: 50px;
    }
  }
  
  @media (max-width: 680px) {
  
    .Form_action__1KdBi, .Form_action__1KdBi button {
      width: 100%;
    }
  }
  
  @media (max-width: 450px) {
    
    .Form_form_div__1C5Fd {
      padding: 20px;
    }
  }

.About_about__fLPwQ{
    width: 100%;
    height: 100%;
}

.About_plx1__xlF-K {
    background-image: url(/static/media/parallax-3.a5b9298c.jpg);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    height: 50vh;
}

.About_plx2__1yczK {
    background-image: url(/static/media/parallax-4.30261f15.jpg);
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    height: 50vh;
}

.About_gallery_div__14rV8 {
    padding-top: 80px;
    padding-bottom: 80px;
}


@media (max-width: 820px) {
    
    .About_gallery_div__14rV8 {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.AboutBrandFull_about_brand_full__1KwOA {
    width: 100%;
    padding: 100px;
    background: url(/static/media/bg-flower-4.2ce81f72.png);
    background-repeat: repeat-y;
    background-position: top;
}

.AboutBrandFull_about_brand_full__1KwOA h2 {
    font-family: 'Cormorant Garamond', sans-serif;
    font-size: 40px;
    text-align: center;
}


@media (max-width: 820px) {
    
    .AboutBrandFull_about_brand_full__1KwOA {
        padding: 50px;
    }

    .AboutBrandFull_about_brand_full__1KwOA h2 {
        font-size: 30px;
    }
}

@media (max-width: 680px) {
    
    .AboutBrandFull_about_brand_full__1KwOA {
        padding: 20px;
    }
}


/* ========= Contents ===========*/
.AboutBrandFull_about_div__2Wnyt, .AboutBrandFull_suitables_div__w_GDJ, .AboutBrandFull_features_div__zATSa, .AboutBrandFull_contact_div__eaQTX {
    width: 70%;
    margin: auto;
}

.AboutBrandFull_suitables_div__w_GDJ, .AboutBrandFull_features_div__zATSa, .AboutBrandFull_contact_div__eaQTX {
    margin-top: 100px;
}

.AboutBrandFull_about_div__2Wnyt h1 {
    font-family: 'Gwendolyn', sans-serif;
    color: #7851a9;
    font-size: 60px;
}

.AboutBrandFull_about_div__2Wnyt p {
    font-family: 'Cormorant Garamond', sans-serif;
    font-size: 30px;
    margin: auto;
    text-align: justify;
}

.AboutBrandFull_underline__1gNYI {
    width: 100px;
    height: 2px;
    background-color: #7851a9;
    margin: 10px;
}

.AboutBrandFull_suitables_div__w_GDJ div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.AboutBrandFull_suitables_div__w_GDJ figure {
    text-align: center;
    margin: 20px;
}

.AboutBrandFull_suitables_div__w_GDJ img{
    width: 300px;
    border-radius: 50%;
}

.AboutBrandFull_suitables_div__w_GDJ figcaption {
    font-family: 'Work Sans', sans-serif;
    font-size: 30px;
    margin: auto 30px;
}

.AboutBrandFull_features_div__zATSa {
    display: flex;
    flex-direction: column;
}

.AboutBrandFull_features_div__zATSa figure {
    display: flex;
    margin: 20px;
}

.AboutBrandFull_features_div__zATSa img{
    width: 100px;
}

.AboutBrandFull_features_div__zATSa figcaption {
    font-family: 'Cormorant Garamond', sans-serif;
    font-size: 30px;
    margin: auto 30px;
    text-align: justify;
}

.AboutBrandFull_contact_div__eaQTX {
    text-align: center;
}


@media (max-width: 1700px) {
    
    .AboutBrandFull_suitables_div__w_GDJ img{
        width: 250px;
    }
}

@media (max-width: 1400px) {
    
    .AboutBrandFull_about_div__2Wnyt, .AboutBrandFull_suitables_div__w_GDJ, .AboutBrandFull_features_div__zATSa, .AboutBrandFull_contact_div__eaQTX {
        width: 80%;
    }
}

@media (max-width: 1250px) {
    
    .AboutBrandFull_about_div__2Wnyt, .AboutBrandFull_suitables_div__w_GDJ, .AboutBrandFull_features_div__zATSa, .AboutBrandFull_contact_div__eaQTX {
        width: 90%;
    }
}

@media (max-width: 1100px) {
    
    .AboutBrandFull_about_div__2Wnyt, .AboutBrandFull_suitables_div__w_GDJ, .AboutBrandFull_features_div__zATSa, .AboutBrandFull_contact_div__eaQTX {
        width: 100%;
    }
}

@media (max-width: 820px) {
    
    .AboutBrandFull_about_div__2Wnyt h1 {
        font-size: 50px;
    }

    .AboutBrandFull_about_div__2Wnyt p, .AboutBrandFull_features_div__zATSa figcaption {
        font-size: 25px;
    }
}

@media (max-width: 680px) {
    
    .AboutBrandFull_features_div__zATSa figure {
        flex-direction: column;
        margin: 0;
    }

    .AboutBrandFull_features_div__zATSa img, .AboutBrandFull_features_div__zATSa figcaption {
        margin: auto;
    }

    .AboutBrandFull_contact_div__eaQTX button {
        width: 100%;
    }
}




.Contact_contact__1lM83{
    width: 100%;
    height: 100%;
}


.ContactDisplayFull_contact_display_full__3uL3I {
    background-image: url(/static/media/bg-flower-3.d89f1e2c.png);
    background-position: center;
}

.ContactDisplayFull_contact_display_full__3uL3I a {
    text-decoration: none;
}

.ContactDisplayFull_top_div__3mdQj, .ContactDisplayFull_bottom_div__2LHIY {
    display: flex;
}


@media (max-width: 1400px) {
    
    .ContactDisplayFull_top_div__3mdQj, .ContactDisplayFull_bottom_div__2LHIY  {
    flex-direction: column;
    }
}


/* === TOP  ========================= */
.ContactDisplayFull_contact_info__3EC1j {
    width: 50%;
    padding: 150px;
}

.ContactDisplayFull_heading__gu2ef {
    font-family: 'Gwendolyn', sans-serif;
    color: #7851a9;
    font-size: 60px;
}

.ContactDisplayFull_underline__2wEYH {
    width: 100px;
    height: 2px;
    background-color: #7851a9;
    margin: 10px;
}

.ContactDisplayFull_sub_heading__3Pg-2 {
    font-family: 'Cormorant Garamond', sans-serif;
    font-size: 35px;
}

.ContactDisplayFull_buttons_div__2K-Vy {
    padding: 10px 0;
}


@media (max-width: 1500px) {
    
    .ContactDisplayFull_contact_info__3EC1j {
    width: 40%;
    padding: 100px;
    }
}

@media (max-width: 1400px) {
    
    .ContactDisplayFull_contact_info__3EC1j {
    text-align: center;
    width: 100%;
    padding-bottom: 0px;
    }

    .ContactDisplayFull_underline__2wEYH {
    margin: 10px auto;
    }
}

@media (max-width: 820px) {
    
    .ContactDisplayFull_contact_info__3EC1j {
    padding: 50px;
    }

    .ContactDisplayFull_heading__gu2ef {
    font-size: 50px;
    }
    
    .ContactDisplayFull_sub_heading__3Pg-2 {
    font-size: 30px;
    }
}

@media (max-width: 680px) {

    .ContactDisplayFull_buttons_div__2K-Vy {
    display: flex;
    flex-direction: column;
    }
}

@media (max-width: 450px) {
    
    .ContactDisplayFull_contact_info__3EC1j {
    padding: 20px;
    }

    .ContactDisplayFull_sub_heading__3Pg-2 {
    font-size: 25px;
    }
}


/* === BOTTOM  ========================= */
.ContactDisplayFull_address_div__J8md- {
    width: 50%;
    padding: 150px;
}

.ContactDisplayFull_address__1Pv0r {
    font-family: 'Work Sans', sans-serif;
    font-size: 25px;
    margin-bottom: 50px;
}

.ContactDisplayFull_email__q5cp4 {
    color: #7851a9;
    font-family: 'Work Sans', sans-serif;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 50px;
}

.ContactDisplayFull_map_div__3KYPH {
    width: 100%;
    min-height: 500px;
    padding: 20px;
}

.ContactDisplayFull_map_div__3KYPH iframe {
    width: 100%;
    height: 100%;
}

@media (max-width: 1500px) {
    
    .ContactDisplayFull_address_div__J8md- {
    width: 40%;
    padding: 100px;
    }
}

@media (max-width: 1400px) {
    
    .ContactDisplayFull_address_div__J8md- {
    text-align: center;
    width: 100%;
    padding-bottom: 0px;
    }

    .ContactDisplayFull_map_div__3KYPH {
        padding: 0;
        height: 80vh;
    }
}

@media (max-width: 820px) {
    
    .ContactDisplayFull_address_div__J8md- {
    padding: 50px;
    }
    
    .ContactDisplayFull_address__1Pv0r {
    font-size: 20px;
    }
    
    .ContactDisplayFull_email__q5cp4 {
    font-size: 20px;
    }

    .ContactDisplayFull_map_div__3KYPH {
        height: 50vh;
        padding: 0;
    }
}

@media (max-width: 450px) {
    
    .ContactDisplayFull_address_div__J8md- {
    padding: 20px;
    }
}

.Gallery_gallery__2DgXO{
    width: 100%;
    text-align: center;
    background: url(/static/media/bg-flower-3.d89f1e2c.png);
    background-repeat: repeat;
    background-position: center;
}

.GalleryDisplay_gallery_display__2vL_p {
    min-height: 75vh;
}

.GalleryDisplay_gallery_display__2vL_p h1 {
    font-family: 'Gwendolyn', sans-serif;
    color: #7851a9;
    font-size: 60px;
    margin-top: 50px;
}

.GalleryDisplay_underline__3hEwX {
    width: 100px;
    height: 2px;
    background-color: #7851a9;
    margin: auto;
}


/*================================*/
.GalleryDisplay_media_div__3rGjT {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 60%;
    margin: 50px auto;
}

.GalleryDisplay_media_div__3rGjT img {
    width: 300px;
    margin: 10px;
    cursor: pointer;
    transition: 0.2s ease;
}

.GalleryDisplay_media_div__3rGjT img:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}


@media (max-width: 1200px) {
    
    .GalleryDisplay_media_div__3rGjT {
        width: 80%;
    }
}

@media (max-width: 850px) {
    
    .GalleryDisplay_media_div__3rGjT {
        width: 90%;
    }
}

@media (max-width: 380px) {
    
    .GalleryDisplay_media_div__3rGjT img {
        width: 250px;
    }
}

/*====================================*/
.GalleryDisplay_main_viewer__2onGW {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    margin: auto;
    background-color: rgb(0, 0, 0, 0.9);
    z-index: 3;
}

.GalleryDisplay_close_btn__3OCBE {
    width: 35px;
    height: 35px;
    margin: 10px;
    position: fixed;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 4;
}

.GalleryDisplay_inner_viewer_div__3Tek6 {
    display: flex;
    justify-content: space-around;
    height: 100%;
    width: 100%;
}

.GalleryDisplay_arrows_div_left__dYL19, .GalleryDisplay_arrows_div_right__3nj3Q {
    height: 100%;
    margin: auto;
}

.GalleryDisplay_arrows_div_left__dYL19 img, .GalleryDisplay_arrows_div_right__3nj3Q img {
    width: 50px;
    margin: auto 20px;
    cursor: pointer;
}

.GalleryDisplay_main_img_div__1QZdX {
    width: 120vh;
    height: 85vh;
    margin: auto;
}

.GalleryDisplay_main_img_div__1QZdX img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}


@media (max-width: 1300px) {

    .GalleryDisplay_arrows_div_left__dYL19 {
        position: fixed;
        left: 0px;
    }

    .GalleryDisplay_arrows_div_right__3nj3Q {
        position: fixed;
        right: 0px;
    }

    .GalleryDisplay_arrows_div_left__dYL19 img, .GalleryDisplay_arrows_div_right__3nj3Q img {
        padding: 10px;
        margin: auto;
        background-color: rgb(0, 0, 0, 0.5);
    }

    .GalleryDisplay_main_img_div__1QZdX {
        width: 100%;
    }

    .GalleryDisplay_main_img_div__1QZdX img {
        width: 100%;
    }
}

@media (max-height: 500px) {

    .GalleryDisplay_main_viewer__2onGW {
        height: 100%;
        overflow: scroll;
    }
}


