
.about_brand {
    width: 100%;
    padding: 100px;
    background: url('../res/bg-flower-1.png');
    background-size: auto;
    background-position: top;
    background-repeat: no-repeat;
}

@media (max-width: 1230px) {

    .about_brand {
        padding: 80px;
    }
}

@media (max-width: 850px) {

    .about_brand {
        padding: 50px;
    }
}

@media (max-width: 680px) {

    .about_brand {
        padding: 20px;
    }
}


/* === Top Side  ========================= */
.about_div_top {
    width: 100%;
}

.about_div, .features_div, .buttons_div {
    width: 70%;
    margin: auto;
}

.about_div {
    text-align: center;
}

.about_div h2 {
    font-family: 'Gwendolyn', sans-serif;
    color: #7851a9;
    font-size: 60px;
}

.underline {
    width: 100px;
    height: 2px;
    background-color: #7851a9;
    margin: 10px auto;
}

.about_div p {
    font-family: 'Cormorant Garamond', sans-serif;
    font-size: 30px;
}

.features_div, .buttons_div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 80px;
}

.features_div figure {
    text-align: center;
    max-width: 200px;
    margin: 20px;
}

.features_div img{
    width: 100px;
}

.features_div figcaption {
    font-family: 'Work Sans', sans-serif;
    font-size: 20px;
    margin: 0 10px;
}


@media (max-width: 1640px) {

    .about_div, .features_div, .buttons_div {
        width: 80%;
    }
}

@media (max-width: 1470px) {

    .about_div, .features_div, .buttons_div {
        width: 90%;
    }
}

@media (max-width: 1340px) {

    .about_div, .features_div, .buttons_div {
        width: 100%;
        margin-top: 60px;
    }
}

@media (max-width: 820px) {

    .about_div h2 {
        font-size: 50px;
    }
    
    .about_div p {
        font-size: 25px;
    }

    .features_div {
        margin-top: 0;
    }
}

@media (max-width: 680px) {
    
    .buttons_div {
        flex-direction: column;
    }
}


/* === Bottom Side  ========================= */
.about_div_bottom {
    margin-top: 100px;
    width: 100%;
}
