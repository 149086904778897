
nav {
    float: right;
}

nav ul {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-right: 30px;
    list-style: none;
}

nav ul li {
    margin-left: 30px;
}

nav a {
    font-size: 25px;
    text-decoration: none;
    color: rgb(255,255,255,0.7);
    transition: 0.3s;
    font-family: 'Raleway', sans-serif;
    text-shadow: 2px 2px 5px #1F2022;
}

nav a.active,
nav a:hover {
    color: rgb(255,255,255,1);
}

nav span {
    margin-left: 30px;
    color: rgb(255,255,255,0.7);
}


@media (max-width: 900px) {
    
    nav a {
        font-size: 22px;
    }
}

@media (max-width: 820px){

    nav {
        flex-direction: column;
    }

    @media (max-height: 350px){

        nav {
            overflow: scroll;
        }
    }

    nav.collapse {
        position: fixed;
        width: 100%;
        height: 100vh;
        background-color: #1F2022;
        right: -100%;
        z-index: 1;
        transition: all 0.5s;
    }

    nav ul {
        flex-direction: column;
    }

    nav ul li {
        margin: 15px;
    }

    nav span {
        display: none;
    }
}